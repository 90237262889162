import uikit from "./uikit.js"
import components from "./components.js"
import Headroom from "headroom.js"
import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from "swiper"
import "swiper/css"

uikit()
components()
function uikitPageDropdown() {
  $(".js-uikit-pages-dropdown").on("click", (e) => {
    $(".front-ui-dropdown").toggleClass("active")
  })
}
uikitPageDropdown()

var app = {
  headroomFn() {
    console.log
    var header = document.querySelector("header")
    var options = {
      offset: {
        up: 100,
        down: 50,
      },
      tolerance: {
        up: 5,
        down: 0,
      },
      classes: {
        initial: "header",
        pinned: "header--pinned",
        unpinned: "header--unpinned",
        top: "header--top",
        notTop: "header--not-top",
        bottom: "header--bottom",
        notBottom: "header--not-bottom",
        frozen: "header--frozen",
      },
      onPin: function () {},
      onUnpin: function () {},
      onTop: function () {},
      onNotTop: function () {},
      onBottom: function () {},
      onNotBottom: function () {},
    }
    var headroom = new Headroom(header, options)
    headroom.init()
  },
  searchButtonFn() {
    const searchBtn = document.querySelector("#js-search-button")
    const Input = document.querySelector(".header__search-input input")
    if (searchBtn) {
      window.addEventListener("click", function (e) {
        if (!document.querySelector(".header__search").contains(e.target)) {
          document.querySelector(".header__search").classList.remove("opened")
          document
            .querySelector(".header__search-results")
            .classList.remove("opened")
        }
      })
      searchBtn.addEventListener("click", function () {
        this.parentNode.classList.toggle("opened")
        let x = this.getAttribute("aria-expanded")
        x = true
        this.setAttribute("aria-expanded", x)
        if (this.parentNode.classList.contains("opened")) {
          x = true
          this.setAttribute("aria-expanded", x)
          if (Input.value.length > 3) {
            document
              .querySelector(".header__search-results")
              .classList.add("opened")
          }
        } else {
          x = false
          this.setAttribute("aria-expanded", x)
          document
            .querySelector(".header__search-results")
            .classList.remove("opened")
        }
        Input.addEventListener("keyup", () => {
          if (Input.value.length > 3) {
            document
              .querySelector(".header__search-results")
              .classList.add("opened")
          } else {
            document
              .querySelector(".header__search-results")
              .classList.remove("opened")
          }
        })
      })
    }
  },
  headerMenuButtonFn() {
    var headerMenuBtn = document.querySelector(".header__nav__menu")
    headerMenuBtn.addEventListener("click", function () {
      if (headerMenuBtn.classList.contains("opened")) {
        headerMenuBtn.classList.remove("opened")
      } else {
        headerMenuBtn.classList.add("opened")
      }
    })
    const menuBtn = document.querySelector(".js-menu-button")
    const menu = document.querySelector(".js-menu")
    const header = document.querySelector(".header")

    menuBtn.addEventListener("click", function () {
      this.classList.toggle("opened")
      menu.classList.toggle("opened")
      header.classList.toggle("menu-opened")
      document.querySelector("html").classList.toggle("menu-opened")
      document.querySelector("body").classList.toggle("menu-opened")
    })
  },
  heroSliderFn() {
    var heroThumbSwiper = new Swiper(".js-hero-bg-slider", {
      loop: true,
      slidesPerView: 1,
      speed: 1000,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      watchSlidesVisibility: true,
    })
    var heroSwiper = new Swiper(".js-hero-slider", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: "100%",
      speed: 1000,
      modules: [Autoplay, Pagination, Navigation, Thumbs],
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        320: {
          spaceBetween: 300,
        },
      },
      pagination: {
        clickable: true,
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".slider-button-next",
        prevEl: ".slider-button-prev",
      },

      thumbs: {
        swiper: heroThumbSwiper,
      },
    })
  },
  heroSubReferenceSliderFn() {
    const heroSubReferenceSwiper = new Swiper(".js-hero-reference-slider", {
      loop: true,
      slidesPerView: 3,
      spaceBetween: 40,
      modules: [Navigation, Autoplay],
      speed: 700,
      autoplay: {
        delay: 2000,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        568: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: ".hero-sub__reference-next",
        prevEl: ".hero-sub__reference-prev",
      },
    })
  },
  heroSubSliderFn() {
    const heroSubSwiper = new Swiper(".js-hero-sub-slider", {
      loop: false,
      slidesPerView: 1,
      speed: 700,
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: ".swiper-pagination",
      },
    })
  },
  productSliderFn() {
    const productSwiper = new Swiper(".js-product-slider", {
      loop: false,
      slidesPerView: 3,
      spaceBetween: 40,
      speed: 700,
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
      modules: [Navigation],
      navigation: {
        nextEl: ".slider-button-next",
        prevEl: ".slider-button-prev",
      },
    })
  },
  membersSliderFn() {
    const heroSubSwiper = new Swiper(".js-members-slider", {
      loop: true,
      slidesPerView: 4,
      spaceBetween: 40,
      speed: 700,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        568: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: ".swiper-pagination",
      },
    })
  },
  newsSliderFn() {
    const newsSwiper = new Swiper(".js-news-slider", {
      loop: true,
      slidesPerView: 2.1,
      spaceBetween: 40,
      speed: 700,
      breakpoints: {
        0: {
          slidesPerView: 1.2,
        },
        768: {
          slidesPerView: 1.8,
        },
        1200: {
          slidesPerView: 2.1,
        },
      },
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: ".swiper-pagination",
      },
    })
  },
  modalFn() {
    $("body").on("click", ".js-open-modal", function (e) {
      e.preventDefault()
      var id = $(this).data("modal-id")
      $('.js-modal[data-modal-id="modal-' + id + '"]').addClass("active")
    })
    $(".js-close-modal").on("click", function () {
      $(".js-modal").removeClass("active")
    })
    $("body").on("click", ".modal-overlay", function () {
      $(".js-modal").removeClass("active")
    })
  },
  load() {
    console.log("load")
    const modal = document.querySelector(".js-modal")
    console.log(modal)
    if (modal) {
      setTimeout(() => {
        modal.classList.add("active")
      }, 300)
    }
  },
  resized() {
    console.log("resized")
  },

  init: function () {
    app.load()
    app.headroomFn()
    app.searchButtonFn()
    app.headerMenuButtonFn()
    app.heroSliderFn()
    app.heroSubReferenceSliderFn()
    app.heroSubSliderFn()
    app.productSliderFn()
    app.membersSliderFn()
    app.newsSliderFn()
    app.modalFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
